:root {
    --theme: #f0f;
    --dark: #f0f;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-550 ease-in-out-quart;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline {
    outline: none !important;
}

html.no-outline * {
    outline: none !important;
}

body {
    will-change: background-color, color;
    @apply transition-colors duration-300 ease-in-out-quad;
}

.ratio {
    padding-bottom: calc(100% / (var(--ratio-small)));
    @media (min-width: 750px) {
        padding-bottom: calc(100% / (var(--ratio-medium)));
    }
    @media (min-width: 1200px) {
        padding-bottom: calc(100% / (var(--ratio-large)));
    }
}
