@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .h1 {
       @apply font-right-serif text-30 leading-1 m:text-60 m:leading-57 l:text-100 l:leading-95;
    }

    .h1-story {
        @apply font-right-serif text-30 leading-1 m:text-50 m:leading-48 l:text-80 l:leading-76;
    }

    .h1-story-new {
        @apply font-right-serif text-30 leading-1 m:text-60 m:leading-57 l:text-64 l:leading-72;
    }
    
    .h2-section {
        @apply font-right-serif text-40 leading-38 m:text-80 m:leading-76 l:text-120 l:leading-114 uppercase;
    }
    
    .h2-section-grotesk {
        @apply font-right-grotesk text-40 leading-38 m:text-80 m:leading-76 l:text-120 l:leading-114 uppercase;
    }
    
    .h2 {
        @apply font-right-grotesk text-24 leading-28 m:text-32 m:leading-36 l:text-60 l:leading-66;
    }

    .h3 {
        @apply font-right-grotesk text-22 leading-24 m:text-24 m:leading-30 l:text-32 l:leading-40;
    }

    .h3-serif {
        @apply font-right-serif text-22 leading-24 m:text-26 m:leading-36 l:text-40 l:leading-48;
    }

    .h4 {
        @apply font-right-grotesk text-18 leading-20 m:text-24 m:leading-24 l:text-40 l:leading-44;
    }

    .h4-serif {
        @apply font-right-serif text-18 leading-20 m:text-24 m:leading-30 l:text-32 l:leading-40;
    }

    .h5 {
        @apply font-right-text font-bold text-15 leading-22 m:text-16 m:leading-26 l:text-20 l:leading-32;
    }

    .ingress {
        @apply font-right-grotesk text-16 leading-22 m:text-30 m:leading-34 l:text-55 l:leading-60;
    }

    .ingress-small {
        @apply font-right-text text-16 leading-22 m:text-18 m:leading-24 l:text-24 l:leading-32;
    }
    
    .body {
        @apply font-right-text text-15 leading-22 m:text-16 m:leading-26 l:text-20 l:leading-32;
    }

    .body-tight {
        @apply font-right-text text-15 leading-20 m:text-16 m:leading-22 l:text-20 l:leading-26;
    }

    .small {
        @apply font-right-text text-12 leading-16 l:text-16 l:leading-18;
    }

    .micro {
        color: red !important;
    }
    

    .button {
        @apply small text-black whitespace-nowrap inline-flex items-center rounded-2px px-12 l:px-16 transition-colors duration-350 ease-out-cubic;
        
        &--primary {
            @apply bg-red-2 h-36px l:h-56px;
        }
        &--secondary {
            @apply bg-green-1 h-32px l:h-40px;
        }
        &--secondary-alt {
            @apply bg-green-2 h-32px l:h-40px;
        }
        
        &--icon {
            svg {
                @apply transition-transform duration-350 ease-out-cubic;
                will-change: transform;
                margin-left: 6px;
                width: 20px;
                height: 20px;
                
                @screen l {
                    margin-left: 8px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    
    .outlined-button {
        @apply uppercase font-right-grotesk text-18 leading-1 l:text-24 border-1 border-current rounded-2px px-20 py-12 l:px-30 l:py-16 hover:bg-yellow-1 hover:border-yellow-1 hover:text-green-4 transition-colors duration-200;
    } 
    
    .default-link {
        @apply underline transition-colors duration-400 ease-out hover:decoration-transparent;
        //text-underline-offset: 0.2em;
        &__faux {
            @apply underline transition-colors duration-400 ease-out;
            //text-underline-offset: 0.2em;
            html.using-mouse a:hover &,
            html.outline a:focus & {
                @apply decoration-transparent;
            }
        }
    }

    .inverted-links {
        a {
            @apply underline decoration-transparent transition-colors duration-400 ease-out hover:decoration-current;
            //text-underline-offset: 0.2em;
        }
        &__faux {
            @apply underline decoration-transparent transition-colors duration-400 ease-out;
            //text-underline-offset: 0.2em;
            html.using-mouse a:hover &,
            html.outline a:focus & {
                @apply decoration-current;
            }
        }
    }

    .vertical-spacing {
        @apply my-32 m:my-64 l:my-80;
        
        &--less-above {
            @apply mb-32 m:mb-64 l:mb-80 -mt-16 m:-mt-48 l:-mt-64;
        }
        
        &--gap {
            @apply gap-y-32 m:gap-y-64 l:gap-y-80;
        }
        
        &--bottom {
            @apply mb-32 m:mb-64 l:mb-80;
        }
    }

    .toggle {
        @apply block bg-red-2 absolute center cursor-pointer;
        width: 100px;
        height: 56px;
        border-radius: 2px;
        padding: 10px;

        input {
            position: absolute;
            opacity: 0;
        }
        
        &-circle {
            @apply block bg-black;
            width: 36px;
            height: 36px;
            border-radius: 2px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            transition: transform 0.35s theme('transitionTimingFunction.in-out-circ');
        }

        input:checked + &-circle {
            transform: translateX(44px);
        }

        html.outline & {
            @apply active:ring focus-within:ring;
        }

        html.using-touch & {
            transform: scale(0.75) translate(0%, 0%);
            top: auto;
            bottom: 16px;
            left: 16px;
            transform-origin: left bottom;
            @screen m {
                bottom: 32px;
                left: 32px;
            }
        }
    }

    .bleed-image {
        padding-bottom: calc(100% / (var(--ratio-mobile)));
        
        @media (min-width: 750px) {
            padding-bottom: calc(100% / (var(--ratio-default)));
        }
    }

    .player-button {
        @apply transition-colors duration-350 ease-out-sine w-[40px] h-[40px] l:w-[50px] l:h-[50px] inline-flex justify-center items-center;
    }

    .waveform-inverted {
        clip-path: inset(0 100% 0 0);
    }

    .filter {
        @apply h3-serif mr-16 l:mr-24 transition-colors duration-400 ease-out-quart;
        &.selected {
            @apply border-b-2 border-current;
        }
    }

    .tag {
        @apply inline-block small l:body py-4 px-8 l:px-12 bg-theme text-white rounded-8px;
        
        &--secondary {
            @apply bg-sand-light border-2 border-theme text-theme;
        }
    }

    .label {
        @apply inline-block small uppercase py-4 px-8 l:px-12 l:py-10 text-black bg-green-2 rounded-2px;
        
        &--on-image {
            @apply bg-yellow-2;
        }
        
        &--secondary {
            @apply bg-green-1;
        }
    }

    .story-hero-image {
        /*
        @screen l {
            width: calc(100% + 80px) !important;
            transform: translateX(-80px);
        }
        *
         */
    }

    .hero-alternate {
        &__wrapper {
            height: calc((var(--vh, 1vh) * 100) - var(--heroOffset, 57px));
            @screen m {
                height: calc((var(--vh, 1vh) * 100) - var(--heroOffset, 95px));
            }
            @screen l {
                height: 115vh;
            }
            @screen xl {
                height: calc(100vw/(1600/1489));
            }
        }
    }

    .popup {
        @apply fixed top-0 left-0 w-full h-full z-10 bg-black bg-opacity-10;

        &__chrome {
            @apply absolute bg-yellow-2 border-2 border-green-1 rounded-2px;
            top: 7px;
            left: 7px;
            width: calc(100% - 14px);
            height: calc(100% - 14px);
            
            @screen m {
                top: 40px;
                left: 32px;
                width: calc(100% - 64px);
                height: calc(100% - 80px);
            }
            
            @screen l {
                left: calc(80px + 47px);
                width: calc(100% - 160px - 94px);
                
                .half & {
                    width: calc(((100% - 160px)/2 - 6.514%));
                }
            }
            
            @screen lp {
                top: 60px;
                height: calc(100% - 120px);
            }
            
            @media only screen and (min-width: 1760px) {
                width: 1506px;
                left: 50%;
                transform: translateX(-50%);
                .half & {
                    left: calc((100vw - 1600px)/2 + 47px);
                    transform: translateX(0);
                    width: 685px;
                }
            }
        }

        &__wrapper {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* For Webkit-based browsers (Chrome, Safari and Opera) */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    
    .image-overlay {
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 31.77%, rgba(0, 0, 0, 0.5) 50.45%, rgba(0, 0, 0, 0.5) 74.93%, rgba(0, 0, 0, 1) 91.28%);
    }
    
    .content-banner-medium-overlay {
        background: linear-gradient(0deg, #3A363C 39.82%, rgba(58, 54, 60, 0.00) 82.53%);    
    }
    
    .content-banner-large-overlay {
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 31.77%, rgba(0, 0, 0, 0.8) 50.45%, rgba(0, 0, 0, 0.8) 74.93%, rgba(0, 0, 0, 1) 91.28%);
    }
    
    .image-mask-1 {
        mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M40 20c0-2.81-2.28-5.09-5.09-5.09-1.01 0-2.73.29-4.54.79 1.63-.92 3.06-1.94 3.77-2.65a5.088 5.088 0 0 0 0-7.2 5.088 5.088 0 0 0-7.2 0c-.71.71-1.73 2.14-2.65 3.77.5-1.8.79-3.53.79-4.54 0-2.81-2.28-5.09-5.09-5.09S14.9 2.27 14.9 5.08c0 1.01.29 2.73.79 4.54-.92-1.63-1.94-3.06-2.65-3.77a5.088 5.088 0 0 0-7.2 0 5.088 5.088 0 0 0 0 7.2c.71.71 2.14 1.73 3.77 2.65-1.8-.5-3.53-.79-4.54-.79-2.81 0-5.09 2.28-5.09 5.09s2.28 5.09 5.09 5.09c1.01 0 2.73-.29 4.54-.79-1.63.92-3.06 1.94-3.77 2.65a5.088 5.088 0 0 0 0 7.2 5.088 5.088 0 0 0 7.2 0c.71-.71 1.73-2.14 2.65-3.77-.5 1.8-.79 3.53-.79 4.54 0 2.81 2.28 5.09 5.09 5.09s5.09-2.28 5.09-5.09c0-1.01-.29-2.73-.79-4.54.92 1.63 1.94 3.06 2.65 3.77a5.088 5.088 0 0 0 7.2 0 5.088 5.088 0 0 0 0-7.2c-.71-.71-2.14-1.73-3.77-2.65 1.8.5 3.53.79 4.54.79 2.81 0 5.09-2.28 5.09-5.09Z"/></svg>');
    }
    .image-mask-2 {
        mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M40 20c0-2.74-2.75-4.93-6.4-5.21 2.33-3.15 2.63-6.84.54-8.93-.92-.92-2.18-1.41-3.63-1.41-1.72 0-3.62.71-5.3 1.95C24.93 2.75 22.74 0 20 0s-4.93 2.75-5.21 6.4c-1.68-1.24-3.58-1.95-5.3-1.95-1.45 0-2.71.49-3.63 1.41-1.25 1.25-1.7 3.16-1.22 5.24.29 1.26.9 2.52 1.76 3.69C2.75 15.07 0 17.26 0 20s2.75 4.93 6.4 5.21c-.86 1.16-1.47 2.43-1.76 3.69-.48 2.08-.04 3.99 1.22 5.24.92.92 2.18 1.41 3.63 1.41 1.72 0 3.62-.71 5.3-1.95.28 3.65 2.47 6.4 5.21 6.4s4.93-2.75 5.21-6.41c1.68 1.24 3.58 1.96 5.3 1.96 1.45 0 2.71-.49 3.63-1.41 2.09-2.09 1.79-5.78-.54-8.93 3.65-.29 6.4-2.47 6.4-5.21Z"/></svg>');
    }
    .image-mask-3 {
        mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M40 20c0-2.9-1.69-5.4-4.15-6.57.91-2.56.34-5.53-1.71-7.57s-5.01-2.62-7.57-1.71C25.4 1.7 22.9 0 20 0s-5.4 1.69-6.57 4.15c-2.56-.91-5.53-.34-7.57 1.71s-2.62 5.01-1.71 7.57C1.7 14.6 0 17.1 0 20s1.69 5.4 4.15 6.57c-.91 2.56-.34 5.53 1.71 7.57s5.01 2.62 7.57 1.71C14.6 38.3 17.1 40 20 40s5.4-1.69 6.57-4.15c2.56.91 5.53.34 7.57-1.71a7.254 7.254 0 0 0 1.71-7.57A7.28 7.28 0 0 0 40 20Z"/></svg>');
    }
    .image-mask-4 {
        mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M40 20c0-1.41-.72-2.66-1.92-3.6.75-1.32.94-2.76.4-4.06-.51-1.24-1.58-2.1-2.95-2.53.08-1.54-.37-2.94-1.39-3.96-.92-.92-2.18-1.41-3.63-1.41h-.13c-.53-1.35-1.45-2.4-2.73-2.93-1.2-.5-2.55-.47-3.89.09-.1.04-.21.09-.31.14-.92-1.09-2.12-1.75-3.46-1.75-1.41 0-2.66.72-3.6 1.92-1.32-.75-2.76-.94-4.06-.4-1.23.51-2.1 1.58-2.52 2.94h-.34c-1.45 0-2.71.49-3.63 1.41-.95.95-1.43 2.27-1.4 3.75-.04.02-.08.03-.12.05-1.34.56-2.31 1.49-2.81 2.69-.54 1.29-.45 2.77.23 4.2-1.09.92-1.75 2.12-1.75 3.46 0 1.41.72 2.66 1.92 3.6-.75 1.32-.94 2.76-.4 4.06.51 1.24 1.58 2.1 2.94 2.53-.08 1.57.4 2.97 1.4 3.96.92.92 2.18 1.41 3.63 1.41h.13c.54 1.38 1.5 2.42 2.73 2.93 1.2.5 2.55.47 3.89-.09.1-.04.21-.09.31-.14.92 1.09 2.12 1.75 3.46 1.75 1.41 0 2.66-.72 3.6-1.92 1.32.75 2.76.94 4.06.4 1.23-.51 2.1-1.58 2.52-2.94h.34c1.45 0 2.71-.49 3.63-1.41.97-.97 1.43-2.3 1.4-3.75.04-.02.08-.03.13-.05 1.34-.56 2.31-1.49 2.81-2.69.55-1.33.43-2.8-.23-4.19 1.1-.92 1.75-2.12 1.75-3.46Z"/></svg>');
    }
    
    .input {
        @apply text-16 leading-1 l:text-20 border-2 border-green-2 placeholder-green-2 bg-transparent rounded-2px py-14 px-8 m:p-16 w-full;
        @apply focus:bg-yellow-2 focus:border-green-3;
        
        .has-errors & {
            @apply border-red-3 text-red-3 placeholder-red-3;
        }
        
    }
    
    .input-label {
        @apply text-12 leading-14 l:text-18 l:leading-20; 
    }
    
    .radio,
    .checkbox {
        input {
            appearance: none;
            width: 0;
            height: 0;
            display: block;
        }

        input:focus {
            outline: 0;

            + .checkbox__inner {
                outline: 2px solid currentColor;
                outline-offset: 1px;
            }
        }

        &__inner {
            user-select: none;
        }
    }
    
    .checkbox {
        &__bullet {
            @apply block transition-colors duration-100 border-2 border-green-2;
            width: 24px;
            height: 24px;
            
            @screen l {
                width: 30px;
                height: 30px;
            } 
        }

        input[type="checkbox"]:checked + .checkbox__inner {
            .checkbox__bullet {
                @apply border-green-3;
            }
            
            .checkbox__bullet:after {
                @apply block absolute bg-green-3 center;
                content: '';
                width: 10px;
                height: 10px;
                
                @screen l {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    
    .radio {
        &__inner {
            @apply border-2 border-green-2 bg-yellow-1 dark:border-green-3 dark:bg-green-4 py-3 px-15 l:py-10 l:px-20 min-w-[112px] l:min-w-[156px] text-center;
        }

        input[type="radio"]:checked + .radio__inner {
            @apply border-green-3 bg-green-3 text-yellow-1 dark:border-green-2 dark:bg-green-2 dark:text-green-4;
        }
    }
}
