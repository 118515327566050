/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
.slider {
    height: 24px;
    -webkit-appearance: none;
    background: transparent;
    cursor: ew-resize;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 24px + var(--ratio) * (100% - 24px));
}

/*webkit*/
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: currentColor;
    border: none;
    box-shadow: none;
    margin-top: calc(4px * 0.5 - 24px * 0.5);
}

.slider::-webkit-slider-runnable-track {
    height: 4px;
    border: none;
    border-radius: 0;
    background: #FFA18F;
    box-shadow: none;
}

.slider::-webkit-slider-runnable-track {
    background: linear-gradient(currentColor,currentColor) 0/var(--sx) 100% no-repeat, #FFA18F;
}

/*mozilla*/
.slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: currentColor;
    border: none;
    box-shadow: none;
}

.slider::-moz-range-track {
    height: 4px;
    border: none;
    border-radius: 0;
    background: #FFA18F;
    box-shadow: none;
}

.slider::-moz-range-track {
    background: linear-gradient(currentColor,currentColor) 0/var(--sx) 100% no-repeat, #FFA18F;
}

/*ms*/
.slider::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
}

.slider::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
}

.slider::-ms-thumb {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: currentColor;
    border: none;
    box-shadow: none;
    margin-top: 0;
    box-sizing: border-box;
}

.slider::-ms-track {
    height: 4px;
    border-radius: 0;
    background: #FFA18F;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
}

.slider::-ms-fill-lower {
    height: 4px;
    border-radius: 0;
    margin: 0;
    background: currentColor;
    border: none;
    border-right-width: 0;
}
