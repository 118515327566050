.menubar {
    @apply w-full pointer-events-none fixed bottom-[24px] m:bottom-[50px] z-[998];

    &__button {
        transition: background-color 0.3s, opacity 0.3s;
        
        @apply pointer-events-auto flex flex-none items-center justify-center bg-yellow-2 w-46px h-46px l:w-62px l:h-62px ease-out-cubic hover:bg-green-2 active:bg-green-2;
        
        &.selected {
            @apply bg-green-2;
        }
    }

    &__bar {
        @apply pointer-events-auto inline-flex items-center bg-green-1 rounded-2px overflow-hidden;
    }
    
    &__item {
        @apply relative text-12 leading-14 l:text-18 l:leading-20 h-full whitespace-nowrap p-16 m:px-16 m:py-16 l:px-24 l:py-21 bg-green-1 transition-colors duration-300 ease-out-cubic hover:bg-green-2 active:bg-green-2;
        left: 1px;
        
        @screen m {
            left: 0;
        }
        
        &.selected {
            @apply bg-green-2;
        }
        
        &--menu {
            @apply w-[56px] h-[46px];
        }
        
        &:after {
            display: block;
            content: '';
            width: 1px;
            height: 30px;
            position: absolute;
            background: #000;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            
            @screen l {
                height: 50px;
            }
            
            li:last-child & {
                display: none;
            }
        }
    }

    &__menu {
        @apply fixed top-0 left-0 w-full h-full z-[999] hidden bg-yellow-1 bg-opacity-90;

        &:target, &.open {
            @apply block;

            @screen m {
                display: none;
            }
        }

        &-background {
            position: absolute;
            top: 6px;
            left: 6px;
            width: calc(100% - 12px);
            height: calc(100% - 12px);
            @apply bg-green-4 rounded-2px m:rounded-4px;

            @screen m {
                top: 14px;
                left: 14px;
                width: calc(100% - 28px);
                height: calc(100% - 28px);
            }
        }
    }
    
    &__menu-item {
        @apply border-b-2 border-transparent hover:border-current transition-colors duration-100;
        
        &.selected {
            @apply text-green-1 border-current;
        }
    }
}
