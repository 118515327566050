.wysiwyg {
    p, ul, ol, table {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        &+p {
            margin-top: calc(-40px + 16px);
            
            @screen l {
                margin-top: calc(-40px + 24px);
            }
        }
    }

    ul {
        list-style: none;
        li {
            display: flex;
            &:before {
                content: '→';
                display: inline-block;
                font-size: 1.4em;
                margin-right: 0.5em;
                margin-left: -0.1em;
                margin-top: 0;
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            &:before {
                content: counter(ol-counter) ". ";
                font-weight: 700;
                margin-right: 0.6em;
            }
        }
    }

    li {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &--tight {
        p, ul, ol, table {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            & + p {
                margin-top: 0;
            }
        }
        li {
            margin-bottom: 2px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    a {
        @apply underline underline-offset-2 transition-opacity duration-300 ease-out-cubic hover:opacity-60;
    }

    h2 {
        @apply h3 mb-4;
    }

    h3 {
        @apply h4-serif mb-4;
    }

    h4 {
        @apply h5;
    }

    b, strong {
        @apply font-bold;
    }

    i, em {
        font-style: normal;
    }

    &--popup {
        h2 {
            @apply h4 mb-4 m:mb-16;
        }
    }
}
